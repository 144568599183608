@import './mixins';
@import './variables';

body {
  background-color: #f1f2f3;
  font-family: $fontEuropaRegular;

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .btn-toolbar .btn {
    margin-right: 5px;
    min-width: 105px;
  }

  .btn-toolbar .btn:last-child {
    margin-right: 0;
  }

  .bis-btn-primary {
    background: rgb(0, 72, 118);
    background: linear-gradient(90deg, #0075a7 0%, #004876 100%);
    color: white;
  }

  .bis-btn-primary:hover {
    color: white;
    text-decoration: none;
  }

  .user-box-ouer {
    display: block;
    border-radius: 8px;
    box-shadow: 0 0 12px 0 rgb(0 0 0 / 16%);
    background-color: #fff;
    width: 100%;
    padding: 30px;
    max-width: 100%;
    float: left;
    margin: 10px 0 30px;
  }

  .page-title {
    color: #003153;
    font-size: 29px;
    margin: 12px 0;
    font-weight: 600;
  }

  .tab-data {
    padding: 10px 20px;
    background: white;
    border-radius: 8px;
  }

  .client-management-label {
    padding: 0;
    flex: 0 0 200px;
    text-align: right;
    margin-bottom: 0;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;

    span {
      display: block;
      font-weight: 300;
      font-size: 12px;
    }
  }

  .custom-checkbox label {
    font-size: 14px;
  }

  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .has-search {
    position: relative;
  }

  .has-search .fa {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
  }

  .align-center-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  /* Dropdown CSS Customized */
  p-dropdown .p-dropdown {
    width: 100%;
    max-width: 216px;
    // Dropdwon auto width issue resolved
    // max-width: 175px;
    border: 1px solid #ced4da;
    min-width: 10em;

    &:hover {
      border-color: #ced4da !important;
    }
  }

  .dropdown-w216 {
    p-dropdown .p-dropdown {
      min-width: 216px;
    }

    .btn-block {
      display: flex;
    }
  }

  .empty-dropdown-menu {
    display: none !important;
  }
  /* PrimeNg Tabmenu Customized */
  .p-tabmenu .p-tabmenu-nav {
    .p-tabmenuitem {
      margin-right: 0;
      font-size: 18px;
      border: 0;
      background: transparent;
      border-bottom: 6px solid transparent;

      .p-menuitem-link .p-menuitem-text {
        color: #15364C;
      }

      &.p-highlight {
        letter-spacing: 0;
        background-color: transparent;
        border: 0; 

        .p-menuitem-link .p-menuitem-text {
          color: #1A82C9;
        }
      }

      &:hover {
        background-color: transparent !important;
        border: 0 !important;
        // border-bottom: 6px solid #1A82C9 !important;

        .p-menuitem-link .p-menuitem-text {
          color: #333333;
        }
      }
    }
  }
  /* PrimeNg Tabview Customized */
  .p-tabview {
    padding: 0;

    .p-tabview-panels {
      padding: 0;
      border: none;
      border-top: 1px solid #c1c1c1;
    }

    // &.p-tabview-top {
      .p-tabview-nav {
        li {
          margin-right: 0;
          font-size: 18px;
          border: 0;
          background: transparent;
          // border-bottom: 6px solid transparent;
          background-color: transparent !important;
          a {
            font-weight: 300;
          }


          &:hover {
            background-color: transparent !important;
            border: 0 !important;
            // border-bottom: 6px solid #1A82C9 !important;
            color: #1A82C9;

            a {
              // color: #1A82C9 !important;
            }
          }
        }

        li.p-state-active {
          letter-spacing: 0;
          background-color: transparent;
          border: 0;
          border-bottom: 6px solid #1A82C9;

          a {
            color: #1A82C9;
          }
        }
      }
    // }
  }
  // .btn{
  //   flex-wrap: wrap;
  // -ms-flex-wrap: wrap;
  // }
  .btn-primary {
    height: 35px;
    border-radius: 4px;
    font-size: 13px;
    background: $btn-primary-bg;
    border: 1px solid $btn-primary-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    img {
      margin-left: 10px;
    }

    &:hover {
      background: $btn-primary-bg-hover;
      border: 1px solid $btn-primary-bg-hover;
      color: #fff;
    }
  }

  .btn-outline-primary {
    font-size: 13px;
  }

  .line-btn {
    height: 35px;
    background: #ffffff;
    border: 1px solid $btn-primary-bg;
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
    color: $btn-primary-bg;

    &:hover {
      color: #fff;
      background-color: $btn-primary-bg-hover;
      border-color: $btn-primary-bg-hover;
    }
  }

  .btn-secondary {
    font-size: 13px;
    height: 35px;
    background: $btn-secondary-bg;
    color: $btn-primary-bg;
    border-color: $btn-secondary-bg;

    &:hover {
      background: $btn-primary-bg-hover;
      color: $btn-secondary-bg;
      border-color: $btn-primary-bg-hover;
    }
  }

  .btn-toolbar {
    display: flex;

    .btn {
      img {
        height: 13px;
        margin-left: 5px;
      }
    }

    .btn-light {
      color: $btn-primary-bg;
      background: $btn-secondary-bg;
      border: 1px solid $btn-secondary-bg;
      font-size: 13px;
      height: 35px;

      &:hover {
        color: $btn-primary-bg-hover;
        background: $btn-secondary-bg-hover;
      }
    }
  }

  .btn-custom {
    color: $btn-primary-bg;
    background: $btn-secondary-bg;
    border: 1px solid $btn-secondary-bg;
    font-size: 13px;
    height: 35px;

    &:hover {
      color: $btn-primary-bg-hover;
      background: $btn-secondary-bg-hover;
    }
  }

  p-autocomplete {
    padding: 0 !important;

    span {
      width: 100% !important;
      border: 0;

      input.p-inputtext {
        border: 0;
        width: 100%;
      }
    }
  }
  /* This is for Search Component CSS */
  .search-card {
    background: white;
    padding: 20px;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 10px;
  }

  .card-heading {
    font-size: 20px;
    margin: 10px 0;
  }

  .col-form-label {
    font-size: 14px;
    text-align: right;
    padding-left: 0;
    padding-right: 0;
  }

  .col-form-title {
    font-size: 13pt;
    text-align: right;
    font-weight: 600;
    padding-left: 0;
    padding-right: 0;
    color: grey;
    text-transform: uppercase;
  }

  .user-table-outer {
    width: 100%;
    float: left;
    display: block;
    margin-bottom: 10px;
    margin-top: 0;
  }

  .sub-head {
    width: 100%;
    float: left;
    display: block;
    font-weight: 500;
    font-size: 1.6em;
    color: #6b6868;
  }

  .inner-container .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .right-align {
    float: right;
    width: auto;
    display: block;
  }

  .common-button {
    width: 105px;
    float: left;
    display: block;
  }

  .button-fill-blue {
    background: #15364c;
    border: 1px sollid #15364c;
    color: #fff;
  }

  .common-button a {
    width: 100%;
    float: left;
    display: block;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
    padding: 7px 0;
  }

  .sub-head {
    width: 100%;
    float: left;
    display: block;
    font-weight: 500;
    font-size: 1.6em;
    color: black;
  }

  .top-head-outer-box {
    border-bottom: 1px solid #a8b1b7;
    float: left;
    width: 100%;
    display: block;
    padding-bottom: 30px;
  }

  col-md-12 top-head-outer-box .margin-top-6 {
    margin-top: 6px;
  }

  .search-top-box {
    width: 100%;
    float: left;
    display: block;
    margin-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }


  .width-250 {
    max-width: 250px;
  }
  /* Card  Header  CSS*/
  .tab-headertopline{
    border-top: 1px solid #f0efef;
    padding: 20px 20px 20px 0px;
    padding-top: 30px 0;
    margin: 0;
    margin-bottom: 1.5rem;
    display: flex;

    .tab-header-back-link {
      flex: 0 0 auto;
      font-size: 20px;
      color: #1A82C9;
      cursor: pointer;
      border-right: 1px solid #c1c1c1;
      padding-right: 15px;
    }
  }
  .tab-header {
    border-bottom: 1px solid #f0efef;
    padding: 10px 0;
    margin: 0;
    margin-bottom: 1.5rem;
    display: flex;

    .tab-header-back-link {
      flex: 0 0 auto;
      font-size: 20px;
      color: #1A82C9;
      cursor: pointer;
      border-right: 1px solid #c1c1c1;
      padding-right: 15px;
    }

    .tab-header-title {
      font-size: 18px;
      color: #25282A;
    }

    .tab-header-edit {
      flex: 0 0 auto;
      font-size: 20px;
      color: #1A82C9;
      cursor: pointer;
    }
  }

  .tab-footer {
    border-top: 1px solid #f0efef;
    padding: 1rem 0 0 0;
    margin: 0;
  }

  .p-calendar {
    border: 1px solid #ced4da !important;
    border-radius: 3px !important;

    input {
      width: 90px !important;
      // padding: .375rem 1.75rem .375rem .75rem;
      border: 0 !important;
    }

    .p-inputtext:hover {
      border-color: #ced4da !important;
    }

    .p-button {
      background: none;
      border: 0 ;
      border-left: 0 ;
      padding-top: 0px;
      padding-bottom: 0px;
      .pi {
        font-size: 20px !important;
        color: #ced4da;
      }

      .p-button-label {
        line-height: inherit;
      }

      &:hover {
        background-color: white !important;
        border-color: #ced4da !important;
      }
    }
  }

  .container-fluid {
    max-width: 1256px;
  }

  label {
    font-size: 14px;
    margin-bottom: 0;
    color: #232323;
  }

  .form-row {
    margin-bottom: 20px;
  }

  .separator-line {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      background-color: #BFBFBF;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: 100%;
      width: 1px;
    }
  }

  .form-control:focus, .custom-select:focus, .p-tabview.p-tabview-top .p-tabview-nav li.p-state-active {
    box-shadow: none;
  }

  .custom-select {
    background: none;
    border: 0;
    padding: 0;
  }

  .p-tabview.p-tabview-top .p-tabview-nav li:focus, .p-tabview.p-tabview-bottom .p-tabview-nav li:focus, body .p-tabview.p-tabview-left .p-tabview-nav li:focus, body .p-tabview.p-tabview-right .p-tabview-nav li:focus {
    box-shadow: none;
  }

  .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .p-datatable .p-sortable-column:focus {
    box-shadow: none;
  }

  .p-tabview-panel {
    padding: 0;
    margin-top: 20px;
  }

  .df {
    display: flex;
  }

  .form-control, .custom-select {
    font-size: 14px;
    max-width: 216px;
  }

  .p-datatable .p-datatable-thead > tr > th, .table .thead-light th {
    background-color: #D0D3D4;
    font-size: 12px;
    color: #25282A;
    border: 0;
    font-weight: 600;
  }


  .sub-container th {

    p {
      padding: 2px 0px;
      margin-bottom: 0px;
    }
  }


  .sub-container td {
    font-size: 12px;

    p {
      padding: 2px 0px;
      margin-bottom: 0px;
    }
  }


  .p-datatable .p-sortable-column:not(.p-state-highlight):hover, .p-datatable .p-sortable-column.p-state-highlight {
    background-color: #D0D3D4;
    color: #25282A;
  }

  .p-datatable .p-sortable-column.p-state-highlight .p-sortable-column-icon {
    color: #25282A;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    border: 0;

    a {
      color: #1A82C9;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .p-datatable table, table {
    border: 1px solid #D0D3D4;
  }

  .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: #F2F2F2;
  }
  .sub-container-transactions{
    background: #fff;
    padding: 20px;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: 0px;

    .card-heading {
      font-size: 18px;
      color: #232323;
      margin-bottom: 20px;
      padding-left: 10px;
    }
  }
  .sub-container-invoice {
    background: #fff;
    padding: 20px;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 20px;

    .card-heading {
      font-size: 18px;
      color: #232323;
      margin-bottom: 20px;
      padding-left: 10px;
    }
  }
  .sub-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;

    .card-heading {
      font-size: 18px;
      color: #232323;
      margin-bottom: 20px;
      padding-left: 10px;
    }
  }
}


.p-dropdown{ 
  span {
    // height: 33px;
    display: flex;
    align-items: center;
  }
}
.entries-block{
  .p-dropdown {
    min-width: 69px;
  }
  .p-dropdown-label{
      height: 28px;
      display: flex;
      align-items: center;
  }
}
 .p-paginator {
  display: block !important;
  background: #fff !important;
  border: 0 !important;
  margin-top: 20px !important;
  text-align: right!important;
}
 .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #004E7C !important;
}
.pi-chevron-down {
  &:before{
  font-size: 12px;
  color: #000000;
  }
}
 .p-paginator .p-paginator-pages .p-paginator-page:focus{
  box-shadow: none;
}
:focus {
  outline: 0;
}
body .p-dropdown:not(.p-state-disabled).p-state-focus{
  box-shadow: none;
}
.pi-sort:before {
  content: "\e915" ;
  color: #000000;
  font-size: 14px;
}
.pi-sort-alt:before {
  content: "\e915" !important ;
  
  color: #000000;
  font-size: 14px;
}
.pi-sort-amount-up-alt:before {
  content: "\e914" ;
  color: #000000;
}
.pi-sort-amount-down:before {
  content: "\e913";
  color: #000000;
}
 .p-paginator .p-paginator-pages .p-paginator-page{
  border: 1px solid #D0D3D4 !important;
  margin: 0 5px !important;
  min-width: 2.106em;
  height: 2.086em;
}
  // .container {
  //   display: block;
  //   position: relative;
  //   padding-right: 35px;
  //   margin-bottom: 12px;
  //   cursor: pointer;
  //   user-select: none;
  //   input[type=checkbox] {
  //     position: absolute;
  //     opacity: 0;
  //     cursor: pointer;
  //     height: 0;
  //     width: 0;
  //     &:checked {
  //       ~ {
  //         .checkmark {
  //           border: 1px solid #e2e2e2;
  //           border-radius: 4px;
  //           background: #fff;
  //           &:after {
  //             display: block;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   &:hover {
  //     input {
  //       ~ {
  //         .checkmark {
  //           background-color: #fff;
  //         }
  //       }
  //     }
  //   }
  //   .checkmark {
  //     &:after {
  //       left: 9px;
  //       top: 5px;
  //       width: 5px;
  //       height: 10px;
  //       border: solid  #707070;
  //       border-width: 0 3px 3px 0;
  //       -webkit-transform: rotate(45deg);
  //       -ms-transform: rotate(45deg);
  //       transform: rotate(45deg);
  //     }
  //   }
  // }
  // .checkmark {
  //   position: absolute;
  //   top: 0;
  //   right: 11px;
  //   height: 25px;
  //   width: 25px;
  //   border: 1px solid #e2e2e2;
  //   border-radius: 4px;
  //   background: #fff;
  //   &:after {
  //     content: "";
  //     position: absolute;
  //     display: none;
  //   }
  // }
  .date-range{
    display: flex;
    p-calendar{
      &:first-child{
        margin-right: 5px;
      }
      .p-calendar{
        display: flex;
      }
    }
    input{
      font-size: 13px;
      border-right:0 !important;
    }
  }
  body .p-calendar input {
  min-width: 100px;
  }

  body .p-inputtext {
    font-size: 14px;
    color: #333333;
    background: #ffffff;
    padding: 0.429em;
  }
  .fs13{
    font-size: 13px;
  }
  div#datetimepicker1{
    align-items: center;
    margin-right: 10px;
    input{
      border-radius: .25rem;
    }
  }
  p-datatable {
    width: 100%;
}
.p-datatable table{
  table-layout: auto;
}

#EffectiveDate, #ExpirationDate {
    width: 184px;
    min-width: 184px;
    max-width: 184px;
    .p-calendar{
      width: 217px;
      min-width: 217px;
      max-width: 217px;
      display: flex;
      border: 1px solid #ced4da;
      border-radius: 3px;
      input{
        width: 184px;
        min-width: 184px;
        max-width: 184px;
      }
    }
    &.error{
      .p-calendar{
      border-color: red;
      }
    }
  }
.form-control{
  padding: 5px;
  height: calc(1.5em + 0.75rem + 2px);
}
.p-dialog-wrapper{
  background-color: rgba(33, 33, 33, 0.85);
}

body{
  .p-tabmenu .p-tabmenu-nav{
    border-bottom: none;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:focus{
    box-shadow: none;
  }
  .p-inputtext:enabled:focus:not(.p-state-error){
    box-shadow: none;
    border-color: #ced4da;
  }
  .span-star{
    color: white;
  }
  .red{
    color: red;
  }

  .error {
    color: #d40909;
    font-size: 12px;
  }
  .input-error {
    border: 1px solid #d40909;
  }

   .back-btn {
    color: #1A82C9;
      background-color: transparent !important;
      display: flex;
      max-width: 200px;
      border: 0;
      align-items: center;
      padding: 0px;
      .fa-angle-left{
        font-size:18px;
      }
      
      .p-button-label{ padding:4px 10px !important;}
  }

    .small-checbox{
        label{
        font-size: 11px;
        // color: #8C8C8C;
      }
    }
  .p-dialog {
    width: 95%;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
  }
  .p-dialog-resizable .p-dialog-content {
    overflow-x: hidden;
  }
 .p-dialog .p-dialog-titlebar {
  background: #fff;
  font-size: 22px;
  font-weight: normal;
  padding: 20px 0;
  margin: 0 20px;
  border: 0;
  border-bottom: 1px solid #c8c8c8;
}
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container{
  width: 100%;
  min-height: 67px;
  border-color: #E2E2E2;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

   .p-dialog{
    .p-dialog-content{
      border: 0;
      padding: 20px;
    }
  }
  .align-center-right{
    .btn-primary{
      font-size: 14px;
    }
  }
  .btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show>.btn-light.dropdown-toggle:focus{
    box-shadow: none;
  }
  .p-dialog{ .p-dialog-footer{
    border: 0;
    background-color: transparent;
    padding: 20px;
  }
}
.mw630{
  max-width: 630px;
}
.table thead th {
  background-color: #D0D3D4;
  font-size: 12px;
  color: #25282A;
  border: 0;
  font-weight: 600;
}
table tbody > tr:nth-child(even) {
  background-color: #f9f9f9;
}
.table td, .table th {
  padding: 0.571em 0.857em;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  font-size: 12px;
}
}
.w123{
  width: 123px ;
}
.txt-align-right{
  justify-content: flex-end;
}
.allw150{
  width: 150px !important;
  min-width: 150px;
  max-width: 150px !important;

}
.mlr15{
  margin:  0 15px;
}
h4.create-bond-lead-label {
  margin-top: 30px;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 18px;
}
.referral-alert-block{
  .p-tabview {
      .p-tabview-nav {
        background-color: #ECECEC !important;
        display: flex;
        align-items: center;
        height: 37px;
          li {
            // height: 37px;
            border: 0;
            margin: 0;
            
            a{
              padding: 0 10px;
              font-size: 16px;
              border: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
               width: 106px;
               background-color: transparent;
              &:hover{
                color:#15364C !important;
              }
            }
            &.p-highlight{
              border: 0;
              background: #1a82c9;
              height: 100%;
              top: 0;
              width: 106px;
              a{
                color: #fff;
                background: #1A82C9;
                border: 0;
                position: relative;
                &:hover{
                  color: #fff !important;
                }
                &:after{
                  content: "";
                  position: absolute;
                  width: 0;
                  height: 0;
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-top: 10px solid #1a82c9;
                  bottom: -8px;
                }
              }
            }
            &:hover{
              border: 0 !important;
            }
          }
      }
  }
  .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container{

    max-width: 1140px;
  }
  p-selectbutton{
    .p-selectbutton {
        width: 100%;
        min-height: 62px;
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}
.p-selectbutton .p-button {
  margin: 15px 5px;
  border-radius: 20px !important;
}
}
li.p-autocomplete-token.p-state-highlight.p-corner-all.ng-tns-c92-16.ng-star-inserted {
  color: #25282A;
  width: 120px;
  height: 35px;
  background: #fff;
  border: 1px solid #C2C2C2;
  border-radius: 100px;
}
.p-autocomplete-token-icon {
  margin-top: 0;
  // position: absolute;
  right: 8px;
  top: 50%;
  cursor: pointer;
  max-width: 20px;
  // transform: translatey(-50%);
}
li.p-autocomplete-input-token input{
    width: 20em !important;
}
body .p-breadcrumb ul li .p-menuitem-link {
  color: #1A82C9 !important;
  font-weight: 600 !important;
  font-size: 11px !important;
  text-transform: uppercase !important;
}
body .p-breadcrumb ul li:first-child a {
  color: #1A82C9 !important;
}
body .p-breadcrumb {
  border: 0;
  box-shadow: 0px 0px 4px #00000029;
  border-radius: 0;
}
body .p-component{
  font-family: $fontEuropaRegular !important ;
}
body .p-breadcrumb ul li.p-breadcrumb-chevron{
   margin: 0 2px;
  &::before{
    content: "/";
    font-size: 18px;
    font-weight: 800;
    color: #1A82C9;
  }
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
   color: #1A82C9 !important;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #1A82C9 !important;
}
body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem{
  margin: 0;
}
a.toltip-hover {
  color: #1A82C9;
  font-size: 14px;
  cursor: pointer;
}
body .p-listbox {
  height: 65px;
  width: 100%;
}
.referral-alert-block{
  p-multiselect {
    width: 216px;
    .p-multiselect {
        width: 100%;
    }
    .p-multiselect-filter-container{
        width: calc(100% - 20px) !important;
    }
}
}

.email-notification-block{
    p-multiselect{
      .p-multiselect{
          width: 216px;
          height: 34px;
          .p-multiselect-label{
            text-align: left;
          }
          .p-multiselect-filter-container{
              width: calc(100% - 20px);
          }
     }
    }
  }
  .p-accordion{
    .p-accordion-toggle-icon.pi-chevron-down {
      &:before{
        font-size: inherit;
        color: #fff;
      }
    }
  }
  body ul.profile-block {
    .p-component {
      top: 50px !important;
      left: -40% !important;
      right: 32% !important;
      border-radius: 10px !important;
      &:after, &:before{
        left: 33%;
        // right: 50%;
        margin: 0 auto;
      }
    }
    .ng-star-inserted{
      a {
        padding: 10px;
        margin-bottom: 10px;
        display: flex;
        border-bottom: 1px solid #ccc !important;
      }
    }
    .p-overlaypanel .p-overlaypanel-content{
      padding: 0;
    }
  }
  .index9{
    position: fixed;
    z-index: 111100 !important;
  }

  .p-overlaypanel .p-overlaypanel-content{
    // padding: 0;
    // .p-component {
      top: 50px !important;
      left: -40% !important;
      right: 32% !important;
      border-radius: 10px !important;
      &:after, &:before{
        left: 33%;
        // right: 50%;
        margin: 0 auto;
      }
    }
  // }
  .p-overlaypanel-content {
    .p-component {
      // top: 50px !important;
      // left: -40% !important;
      // right: 32% !important;
      // border-radius: 10px !important;
      &:after, &:before{
        left: 33%;
        // right: 50%;
        margin: 0 auto;
      }
    }
    .ng-star-inserted{
      a {
        padding: 10px;
        margin-bottom: 10px;
        display: flex;
        border-bottom: 1px solid #ccc !important;
      }
    }
    
  }
  .label-input-block{
    // .p-autocomplete-token-icon{
    //   display: none;
    // }
    .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container{
      li{
        background: none;
            background-color: #dadada;
        border: 1px solid #dadada;
        color: #333333;
        overflow: hidden;
        margin: 5px;
        border-radius: 20px !important;
        &:last-child{
          background-color: transparent;
          border: 0;
        }
      }
    }
  }

.ml5{
  margin-left: 5px;
}
.btn{
  -ms-flex-wrap: wrap;
}

.mw100{
  max-width: 100px;
  text-align: center;
}
.entries-block {
 display: -ms-flexbox;
 display: flex;
 -ms-flex-align: center;
 align-items: center;
}
.ml5{
 margin-left: 5px;
}
.mt10{
  margin-top: 10px;
}
.pt0{
  padding-top: 0 !important;
}

.table-scroll{
  .p-datatable.p-component {
      max-height: 150px;
      overflow: hidden;
      overflow-y: auto;
  }
}

.flex-end{
  justify-content: flex-end;
}
.input-dis{
  background-color: #e9ecef;
}
.dashboard-tile {
  .tile {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
    margin: 15px;
    width: 230px;
    height: 222px;
    align-items: center;
    text-decoration: none;
}
}
body{
  .DragDrop-block{
    .p-fileupload{
      height: 240px;
      width: 620px;
      background: #F1F1F1;
      padding: 20px;
      border: 2px dashed #B1ADAD;
      border-radius: 5px;
      position: relative;
      .p-fileupload-content {
        background-color: transparent;
        padding: 0;
        border: 0;
        text-align: center;
        color: #686868;
        font-size: 24px;
    }
        .p-fileupload-buttonbar {
          background-color: transparent;
          padding: 0.571em 1em;
          border: 0;
          color: #333333;
          border-bottom: 0 none;
        }
        .p-fileupload-buttonbar {
          text-align: center;
          position: absolute;
          bottom: 25px;
          left: 0;
          right: 0;
          margin: 0px auto;
          .p-button{
            font-size: 14px;
            width: 140px;
            height: 35px;
            background: $btn-primary-bg;
            border: 1px solid $btn-primary-bg;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            .pi-plus {
              display: none;
            }
            .p-button-label{
              padding: 0;
            }
          }
      }
      }
  }
}
.anchor-link {
  color: #1A82C9 !important;
  font-size: 16px;
  font-weight: 600;
}
div.p-component.p-tooltip { z-index: 99999999 !important; }
/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1A82C9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1A82C9;
}

body .p-dialog-resizable .p-dialog-content{
  overflow-y: scroll !important;
}
.df-fdc{
  display: flex;
  flex-direction: column;
}
.light-color{
  color:#8C8C8C;
}
.popup-block{
  .p-autocomplete-token{
    overflow: auto;
  }
}
div.p-component.p-tooltip { z-index: 99999999 !important; }



// .ng-valid[required], .ng-valid.required  {
//   border-left: 5px solid #42A948; /* green */
// }

// .form-control.ng-invalid:not(form)  {
//   border: 1px solid #a94442; /* red */
// }

body{
  .DragDrop-block{
    .p-fileupload{
      height: 240px;
      width: 620px;
      background: #F1F1F1;
      padding: 20px;
      border: 2px dashed #B1ADAD;
      border-radius: 5px;
      position: relative;
      .p-fileupload-content {
        background-color: transparent;
        padding: 0;
        border: 0;
        text-align: center;
        color: #686868;
        font-size: 24px;
    }
        .p-fileupload-buttonbar {
          background-color: transparent;
          padding: 0.571em 1em;
          border: 0;
          color: #333333;
          border-bottom: 0 none;
        }
        .p-fileupload-buttonbar {
          text-align: center;
          position: absolute;
          bottom: 25px;
          left: 0;
          right: 0;
          margin: 0px auto;
          .p-button{
            font-size: 14px;
            width: 140px;
            height: 35px;
            background: $btn-primary-bg;
            border: 1px solid $btn-primary-bg;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            .pi-plus {
              display: none;
            }
            .p-button-label{
              padding: 0;
            }
          }
      }
      }
  }
}
.dfaligncenter{
  display: flex;
  align-items: center;
}
.input-group-text{
  height: 35px;
}

body{
  .isViewOnly{
    // p-dropdown {
    //   .p-dropdown, .form-control, .p-inputtext, input, .input-group>.form-control, input{
    //     background-color: #e9ecef !important;
    //     opacity: 1;
    //   }
    // }
    // .form-control, .p-inputtext, input, .input-group>.form-control, input, .p-calendar,  .p-calendar-w-btn, .p-dropdown-trigger, textarea, p-inputmask {
    //   background-color: #e9ecef !important;
    //   opacity: 1;
    // }

    .viewModeHide {
      display: none;
    }

    .isfConfirmationNo {
      background-color: white !important;
      pointer-events: all;
    }
  }
}

table a{
  cursor: pointer;
}
.form-group{
  align-items: center;
}
.alignbasling{align-items: baseline;}

p-dropdown.is-invalid .p-dropdown, p-calendar.is-invalid span.p-calendar, p-radiobutton.is-invalid .p-radiobutton .p-radiobutton-box {
  border: 1px solid #dc3545 !important;
}

p-inputmask.form-control, p-inputmask.form-control.ng-invalid, p-inputmask.form-control.ng-dirty {
  padding: 0;

  .p-inputtext {
    border: 0 !important;
  }
}
.p-fileupload-row{
   font-size: 15px;

}

.back-page-link{ font-size: 14px;
  padding: 4px 15px;
  border-right: 1px solid #ccc;

  .fa-angle-left{ font-size:18px; padding-right: 10px;}
}


.tab-header-back-link{cursor: pointer;}

.btn-link{ cursor: pointer;}

.actionGDP{
  text-align: right;

button {
    padding: 0px 10px;
    font-size: 12px;
    margin-right: 1rem;
    border:1px solid #1A82C9;
    color:#1A82C9;
}
}

.btn-link:hover {
  color: #1A82C9;
}

.btn-link {
  cursor: pointer;
  color:#1A82C9;
}

.text-primary {
  color:#1A82C9!important;
}



/*Disabled*/
// input:disabled, p-calendar button:disabled, p-dropdown .p-state-disabled {
//   opacity: 1 !important;
//   background: #e9ecef !important;
// }

// .p-dropdown.p-state-disabled .p-dropdown-trigger, .p-dropdown.p-state-disabled .p-dropdown-label {
//     cursor: default;
//     background: #e9ecef;
// }

input.ng-touched.ng-invalid {
  border: 1px solid #dc3545;
  padding-right: calc(1.5em + .75rem);
  background-image: url('../assets/images/alert.svg');
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

p-dropdown.custom-dropdown .p-disabled {
  opacity: 1;

  .p-inputtext  {
    background: #e9ecef;
    color: #495057;
  }

  .p-dropdown-trigger {
    background: #e9ecef
  }
}

.custom-input-mask {

  input.p-component {
    width: 100%;
    border: 1px solid #ced4da;

    &:disabled{
    background: #e9ecef;
    opacity: 1;
    }
  }
}

.custom-calendar {
  .p-calendar-w-btn {
    input.p-inputtext:disabled {
      background: #e9ecef;
      opacity: 1;
    }
    > .p-state-disabled{
      background: #e9ecef;
      opacity: 1;
    }
  }
}

.custom-calendar.disabled-control {
  display: inline-block;
  border-radius: 3px;
}

.custom-input-group {
  max-width: 216px;
}

.print-download-modal{
    .p-dialog {
        .p-dialog-titlebar-icons {
            float: right;
            position: absolute !important;
            right: 0px !important;
            top: 19px !important;
        }
    }
    }
.success-dialog{
    .p-dialog {
            .p-dialog-titlebar {
            font-size: 22px;
            font-weight: normal;
            padding: 25px 0;
            margin: 0;
            border: 0;
            border-bottom: 1px solid #c8c8c8;
            background: #75e09d url(../assets/images/checkWhite.svg) center no-repeat;
        }
        .p-dialog-titlebar-icons {
            display: none;
        }
    }
    .p-dialog-resizable{
        .p-dialog-content {
            overflow-y: inherit !important;
        }
    .email-sent{
        font-size: 16px;
    }

    }
    .success-btn{
        .btn{
            display: inline-block;
            color: white;
        }
    }
    .yes-btn{
        height: 38px;
        border-radius: 4px;
        font-size: 13px;
        background: $btn-primary-bg;
        border: 1px solid $btn-primary-bg;
        color: #fff;
    }
}

.error-dialog{
    .p-dialog {
            .p-dialog-titlebar {
            font-size: 22px;
            font-weight: normal;
            padding: 25px 0;
            margin: 0;
            border: 0;
            border-bottom: 1px solid #c8c8c8;
            background: #DB2727 url(../assets/images/error.svg) center no-repeat;
        }
        .p-dialog-titlebar-icons {
            display: none;
        }
    }
    .p-dialog-resizable{
        .p-dialog-content {
            overflow-y: inherit !important;
        }
    .email-sent{
        font-size: 16px;
    }

    }
    .success-btn{
        .btn{
            display: inline-block;
            color: white;
        }
    }
    .yes-btn{
        height: 38px;
        border-radius: 4px;
        font-size: 13px;
        background: $btn-primary-bg;
        border: 1px solid $btn-primary-bg;
        color: #fff;
    }
}

.warning-dialog{

    .p-dialog {
      // p-dialog-titlebar-icon
            .p-dialog-titlebar {
            font-size: 22px;
            font-weight: normal;
            padding: 10px 45px;
            margin: 0;
            border: 0;
            border-bottom: 1px solid #c8c8c8;
            background: #EBB25B url(../assets/images/error.svg) left/30px no-repeat;
            background-origin: content-box;
            padding-left: 208px;
        }
        .p-dialog-titlebar{
          background-color: #EBB25B;
          .p-dialog-title {
            margin-left: 34px;
          }
        }

        // .p-dialog-titlebar-icons {
        //     display: none;
        // }

    }
    .p-dialog-resizable{
        .p-dialog-content {
            overflow-y: inherit !important;
        }
    .email-sent{
        font-size: 16px;
    }

    }
    .success-btn{
        .btn{
            display: inline-block;
            color: white;
        }
    }
    .yes-btn{
        height: 38px;
        border-radius: 4px;
        font-size: 13px;
        background: $btn-primary-bg;
        border: 1px solid $btn-primary-bg;
        color: #fff;
    }
}

.quickActionsMenuList{

    .p-menu.p-menu-dynamic{
         width:208px
        }
    .p-menu {
        a.p-menuitem-link{
        position: relative;
        border-bottom:1px solid #E1E1E1 ;
            .p-menuitem-text{
            color:#1A82C9!important;
                }
        }

        a.p-menuitem-link:hover{
            background:#1A82C9!important;

            .p-menuitem-text{
                color:#fff!important;
                }
        }

        a.p-menuitem-link:before{
            background:#1A82C9;
            content:"";
            height: 8px;
            width: 8px;
            display: inline-block;
            margin-right: 15px;
        }

        a.p-menuitem-link:hover:before{
            background:#fff;
        }
    }

}

.disabled-control:not(p-radiobutton) {
    pointer-events: none !important;
    background-color: #e9ecef !important;

    .p-dropdown-label, .p-inputtext , .p-dropdown .p-dropdown-trigger, .p-button {
        background-color: #e9ecef !important;
    }
}

p-radiobutton.disabled-control {
  pointer-events: none;
  background-color: transparent;
  opacity: .40;
}

.dropdown-load-icon{
    position: absolute;
    right: 12px;
    top: 30px;
    background: #fff;
}
.payment-to-market-select-box{
    .dropdown-load-icon{
        right: 25px;
    }
}
.abi-user-dropdrown-loader{
    .dropdown-load-icon{
        right: 25px;
    }
}
.width100{
    width: 100%;
}

body .address {
  max-width: 100%;
  // resize: none;
}

.col-label-large {
  line-height: 1.35;
  padding: 0;
}

/**** Only p-dropdown in Merchandise Section ****/
body {
  p-dropdown#custom-dropdown .p-dropdown.p-component {
      max-width: 100%;
  }
}
.reconciliation{
    .p-paginator {
        background: #f1f2f3;
        border: 0;
        margin-bottom: 20px;
        text-align: right;
        width: 70%;
        float: right;
      }

      .entries-block{
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        position: absolute;
        top: 185px;
        z-index: 5;
        margin-left: 16px;
    }
}
.invalid-transaction{
    .p-datatable-wrapper{
        height: 400px !important;
        padding-top: 70px;
    }

    .entries-block{
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        position: absolute;
        top: 95px;
        z-index: 2;
    }

    .p-paginator{
        background: #fff;
        border: 0;
        margin-bottom: 20px;
        text-align: right;
        // position: -webkit-sticky;
        // position: sticky;
        // width: 100%;
        float: right;
        position: fixed;
        // right: 157px;
        right: 12%;
        z-index: 6;
    }
    body .p-dialog-resizable .p-dialog-content{
        overflow: scroll !important;
      }
      .p-dialog-resizable .p-dialog-content{
          overflow: inherit !important;
      }
}

a.edit-save-search {
  color: #1A82C9;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

body p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border: none;
}

#mock-last-entrydate-dialog .p-dialog-content.p-widget-content {
  overflow: visible !important;
}

bis-edit-search .alert {
  display: none;
}

.form-control.max-width-100 {
    max-width: 100%;
}

.user-rider-dialog .p-dialog {
    width: 80%;
}

.rider-dialog .p-dialog {
    width: 85%;
}

.void-dialog .p-dialog {
  width: 50%;
}

.loading-position-right {
    position: absolute;
    top: 8px;
    right: -18px;
}

.p-toast-top-right{
  z-index: 3 !important;
}

.dropdown-menu{
  z-index: 4 !important;
}

.p-dialog-wrapper{
  z-index: 2 !important;
}

.p-dialog{
  z-index: 2 !important;
}

option:disabled{
  color: #999999;
}

.p-toast-detail{
  white-space: pre-line;
}

@media only screen and (max-width: 1200px) {
  .user-rider-dialog {
      .p-dialog{
          width: 90%;
      }

      .create-bond-form-field {
          flex: 0 0 220px !important;
      }
  }

  .rider-dialog {
    .p-dialog{
        width: 95%;
    }

    .allw150 {
      min-width: 140px;
    }
  }

}
.v-align-top{
    vertical-align: top;
}

.warning {
    color: #EBB25B;
    font-size: 12px;
  }

  .bond-currency{
    cursor: auto !important;
    border-color:  #d0d2d3 !important;
  }
.disabled-img-icon{
  opacity: 0.4;
  pointer-events: none;
}

html, body { height: 100%; }
body { 
  margin: 0;
   font-family: Roboto, "Helvetica Neue", sans-serif;
 }


.account-list-table tr td:nth-child(1), .account-list-table tr th:nth-child(1), .account-list-table tr th:nth-child(2),  .account-list-table tr td:nth-child(2) {
  width:35% !important;
}
.account-list-table tr td:nth-child(3), .account-list-table tr th:nth-child(3), .account-list-table tr th:nth-child(4), .account-list-table tr td:nth-child(4) {
  width: 15% !important;
}
.account-list-table tr td {
  font-size: 12px;
}
.drop-out  p-dropdown .p-dropdown {
  max-width: 100%;
}
.search-list-bx {
  margin-top: 0 !important;
}

.search-list-bx .col-12, .search-list-bx .col-12 .search-card {
  padding-top: 0;
  padding-bottom: 0;
}
.tblisting-outer tr td:nth-child(1), .tblisting-outer tr th:nth-child(1),
.tblisting-outer tr td:nth-child(4), .tblisting-outer tr th:nth-child(4) {
  width: 24% !important;
}

.tblisting-outer tr td:nth-child(2), .tblisting-outer tr th:nth-child(2),
.tblisting-outer tr td:nth-child(3), .tblisting-outer tr th:nth-child(3),
.tblisting-outer tr td:nth-child(5), .tblisting-outer tr th:nth-child(5),
.tblisting-outer tr td:nth-child(6), .tblisting-outer tr th:nth-child(6) {
  width: 13% !important;
}
.top-head-outer-box .sub-head {
  width: 70%;
  font-size: 20px;
}
.sub-text {
  width: 100%;
  font-size: 15px;
  color: #333333;
}
.sub-toggle-bt {
  float: left;
  width: 100%;
  margin-top: 16px;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 50px;
  height: 20px;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 18px;
}

.slider.round:before {
  border-radius: 50%;
}
.toggle-font {
  font-size: 17px;
  color: #333333;
  padding-left:5px;
}

.search-card .cal .p-datepicker {
    top: inherit !important;
    position: absolute;
}

.cal {
    position: absolute;
    top: 0px;
}

.accordian-space{
  margin-top: 15px;
  display: inline-block;
  width: 100%;
}
:host ::ng-deep .fc-header-toolbar {
  display: flex;
  flex-wrap: wrap;
}
// body .p-datatable table, body .table .fc-scroll grid .fc-scrollgrid .fc-scrollgrid-liquid{
//   width: 100% !important;
// }

body .p-panel .p-panel-titlebar {
  border: 0 none;
  padding: 0;
  background-color: #f4f4f4;
  color: #333333;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-weight: normal;
  }
  body .p-panel .p-panel-titlebar .p-panel-title {
  vertical-align: middle;
  font-weight: normal;
  }
  body .p-component {
  font-size: inherit;
  text-decoration: none;
  }
  .p-panel .p-panel-titlebar-icon {
  float: none;
  position: relative;
  top: 0.3rem;
  }
  
  body .p-panel .p-panel-titlebar .p-panel-titlebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  }

  .tabs-c {
    .p-tabview-nav {
      a {
        background: #F6F5F5 !important;
        min-width: 160px;
        border-top-left-radius: 1rem !important;
        border-top-right-radius: 1rem !important;
      }
      .p-highlight {
        a {
          background: #FFFFFF !important;
          border-top: 4px solid #FE9D2B !important;
          color: #1D2429;
        }
      }

    }
    .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight {
      border-bottom: 0 none !important;
      color: #1D2429;
    }
    .p-tabview.p-tabview-top .p-tabview-nav li:hover {
      border-bottom: 0 none !important;
    }
    .p-tabview-panel {
     margin-top: 0;
    }
}
.tabs-c .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-bottom: 0 none !important;
  color: #1D2429;
  border: 0 none;
}
.card-title-c {
  border-left: 10px solid #FE9D2B;
  border-bottom-left-radius: 0.7rem;
  display: flex;
  align-items: center;

  h4{
    font-size: 1.1rem;
    color: #000000;
    font-weight: 600;
    margin-bottom: 0;
  }

}
.table-border {
  table {
    border-radius: 5px !important;
    border-collapse: collapse !important;
    border: none !important;
    table-layout: fixed !important;
    -webkit-box-shadow: 0px 0px 0px 1px rgba(200,200,200,1);
    -moz-box-shadow: 0px 0px 0px 1px rgba(200,200,200,1);
    box-shadow: 0px 0px 0px 1px rgba(200,200,200,1);
  }
  table th,
  table td {
    border-bottom: 1px solid #C8C8C8 !important;
    border-collapse: collapse;
    background: #fff !important;
  }
  table td {
    border-bottom: 1px solid #E9E9E9 !important;
    &:last-child {
      border-bottom: 0 none;
    }
  }
  table th:first-child {
    border-radius: 5px 0 0 0 !important;
  }
  table th:last-child {
    border-radius: 0 5px 0 0 !important;
  }
  table tr:last-child td:first-child {
    border-radius: 0 0 0 5px !important;
  }
  table tr:last-child td:last-child {
    border-radius: 0 0 5px 0 !important;
  }
  table tr:hover td {
    background-color: #FBFFD5 !important
  }

}

.collapsible-pan {
  .p-accordion-toggle-icon {
    color: #5770AA;
}
.p-accordion-header.p-state-default {
    padding: 0 !important;
  }
  .form-control {
    max-width: 100%;
  }
}

.principalDetailsDialog{
  .p-dialog-content.p-widget-content{
    overflow-y:auto!important;
.p-fieldset{ .p-fieldset-legend{
  margin-bottom:0;
  padding:.2em 0.6em 0 0.6em;
  .p-fieldset-legend-text{
    font-size:1.2rem;
    }
  } 
  .form-group{
    margin-bottom: 0.5rem !important;
 .form-control,  .custom-select{
  height:30px; padding:2px 5px;
}
  label{ font-size:13px;}
}
  .max-100{
    max-width:100%;
    height:auto!important;
  }
}
}
}
body .p-component{
  font-size: 14px !important;
}

.form-group {
  margin-bottom: 1rem;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col {
  padding-left: 5px;
  padding-right: 5px;
}

label {
  margin-bottom: 0.5rem;
}

.mr-3, .mx-3 {
  margin-right: 1rem!important;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.p-datepicker table {
  // font-size: 1rem;
   margin: 0; 
}

.p-datepicker table td > span {
  height: 1.5rem;
  width: 1.5rem;
}


.p-datepicker table th > span {
  height: 1.5rem;
  width: 1.5rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 0;
  width: max-content;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #007ad9;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  margin-left: 0;
}
.pi {
  font-size: 1em !important;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  display: none !important;
}

.p-datatable .p-sortable-column.p-highlight {
  background-color: #D0D3D4;
  color: #25282A;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0rem #8dcdff;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #f1f2f3;
  border-color: #f1f2f3;
  color: #007ad9;
  border: 0;
  border-bottom: 6px solid #1A82C9;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: transparent;
  border-color: transparent;
  color: #333333;
}

// body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-state-active {
//     letter-spacing: 0;
//     background-color: transparent;
//     border: 0;
//     border-bottom: 6px solid #1A82C9;
// }
// .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
//   background: #f1f2f3;
//   border-color: #f1f2f3;
//   color: #333333;
// }
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: 0px solid #c8c8c8;
  border-width: 0px;
  border-color: #f1f2f3;
  background: transparent;
  color: #333333;
  padding: 0.857rem 1rem;
  font-weight: 300;
  font-size: 18px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  transition: background-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -1px 0;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #fff;
  border-color: #fff;
  color: #007ad9;
  border-bottom: 3px soild #007ad9 !important;
  border-bottom-color: #007ad9;
  border-bottom: 7px solid;
}
// .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
//   background: #fff;
//   border-color: #fff;
//   color: #333333;
// }
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  /* border: 1px solid #c8c8c8; */
  /* border-width: 1px; */
  border-color: #fff;
  background: #fff;
  color: #333333;
  padding: 0.857rem 1rem;
  font-weight: 300;
  font-size: 18px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  transition: background-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -1px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0rem #fff !important;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: transparent !important;
  border-color: transparent !important;
  color: #333333;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0rem #8dcdff !important;
  border-color: #007ad9;
}


.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0rem #8dcdff !important;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0.557rem 1rem !important;
  border: 1px solid #c8c8c8;
  color: #333333;
  background: #f4f4f4;
  font-weight: 700;
  border-radius: 3px;
  transition: background-color 0.2s, box-shadow 0.2s;
}
.co-pric-wdth {
  width: 100%;
}



.p-datatable-scrollable .p-datatable-tbody {
  border-radius: 0px 0px 5px  5px!important;
  border-collapse: collapse !important;
  border: none !important;
  table-layout: fixed !important;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(200,200,200,1);
  -moz-box-shadow: 0px 0px 0px 1px rgba(200,200,200,1);
  box-shadow: 0px 0px 0px 1px rgba(200,200,200,1);
}

.p-datatable-wrapper {
  border-radius: 5px 5px 0px 0px !important;
  border-collapse: collapse !important;
  border: none !important;
  table-layout: fixed !important;
  -webkit-box-shadow: 0px 0px 0px 1px rgba(200,200,200,1);
  -moz-box-shadow: 0px 0px 0px 1px rgba(200,200,200,1);
  box-shadow: 0px 0px 0px 1px rgba(200,200,200,1);
}

.p-datatable-scrollable .p-datatable-thead  th:first-child {
  border-radius: 5px 0 0 0 !important;
}
.p-datatable-scrollable .p-datatable-thead  th:last-child {
  border-radius: 0 5px 0 0 !important;
}
.p-datatable-scrollable .p-datatable-thead tr:last-child td:first-child {
  border-radius: 0 0 0 5px !important;
}
.p-datatable-scrollable .p-datatable-thead  tr:last-child td:last-child {
  border-radius: 0 0 5px 0 !important;
}
.p-datatable-scrollable .p-datatable-thead  tr:hover td {
  background-color: #FBFFD5 !important
}

.p-datatable-scrollable .p-datatable-thead>tr>th {
  flex: none !important;
  padding: 0.571em 0.857em;

}

.collateral-table .p-datatable-scrollable .p-datatable-thead>tr>th{
  flex: 1 1 0 !important;
  padding: 0.571em 0.857em;

}

.collateral-table .p-datatable-scrollable .p-datatable-thead>tr>td{
  flex: 1 1 0 !important;
  padding: 0.571em 0.857em;
  text-align: left !important;
}

.collateral-table .p-datatable-scrollable .p-datatable-tbody>tr>td{
  flex: 1 1 0 !important;
  padding: 0.571em 0.857em;
  text-align: left !important;
}


.p-datatable-scrollable .p-datatable-tbody>tr>td {
  flex: none !important;
  padding: 0.571em 0.857em;
}

.paddingright0 {
  padding-right: 0px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  width: 100%;
  font-size: 22px;
  font-weight: 400;
}

.p-dialog .p-dialog-header {
  background: #fff;
    font-size: 22px;
    font-weight: 400;
    padding: 20px 0;
    margin: 0 20px;
    border: 0;
    border-bottom: 1px solid #c8c8c8
}


.p-datatable .p-datatable-thead > tr > th {
  padding: 0.571em 0.8em;;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.571em 0.8em;;
}

.asi-form .p-datepicker .p-datepicker-header {
  padding: 0.5em;

}

.asi-form .p-calendar .p-datepicker {
  min-width: 100%;
  top: -100px !important;
}


.asi-form .p-datepicker table td {
    padding: 0.5em;
}


.p-panel .p-panel-header .p-panel-title {
  font-weight: 400;
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0px;
}
.p-panel .p-panel-header {
  border: 0px;
}

.p-panel .p-panel-header .p-panel-header-icon {
  width: 1rem;
  height: 1rem;
}
.p-panel .p-panel-icons-end {
  margin-left: 0.5rem;
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
  box-shadow: none;
}

//  New styles added for e payment pages

.top-header{
  width: 100%;
  float: left;
  display: block;
}
.middle-block{
  width: 100%;
  float: left;
  padding: 20px 0 30px 0;
  display: block;
}

.epayment-outer{
  width: 100%;
  float: left;
  display: block;
  background: #fff;
  border-radius: 10px;
  padding: 45px;
}
.epayment-outer .left-block{
  width: 50%;
  float: left;
  display: block;
}

.left-subhead{
  width: 100%;
  float: left;
  display: block;
  font-size: 21px;
  padding-bottom: 30px;
  font-weight: 600;
  color:#1A82C9 ;
}
.payment-list-block{
  width: 100%;
  float: left;
  display: block;
  padding-bottom: 30px;
}
.payment-list-block span{
  width: 100%;
  float: left;
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #7E7E7E;
  padding-bottom: 0px;
  line-height: 20px;
}
.payment-list-block p{
  width: 100%;float: left;
  display: block;
  font-size: 17px;
  font-weight: 500;
  color: #000; 
}
.invoice-details-block{
  width: 100%;
  float: left;
  display: block;
}

.invoice-details-block-table {
  width: 100%;
  float: left;
  display: block;
}
.invoice-details-block-table  .table {
  border: 0;
  background: none;
  border-radius: 0;
  outline: none;
  float: left;
  border: 1px solid #16364C;    border-spacing: 0;
  width: 100%;
}
.invoice-details-block-table  .table thead, .invoice-details-block-table .table tbody {
  width: 100%;
  float: left;
  display: block;
}
.invoice-details-block-table  .table thead{
  background: #16364C;
}
.invoice-details-block-table table thead tr{
  width: 100%;
  display: flex;
  float: left;
  padding-left: 10px;

}
.invoice-details-block-table .table>thead>tr>th {
  padding-left: 10px;
  text-align: left;
  line-height: 50px;
  color: #FFFFFF;
  font-size: 14px;
  height: 48px;
  font-weight: 500;padding: 8px;
  line-height: 30px;
  border: 0;
  vertical-align: middle;
}    
.invoice-details-block-table table tbody tr {
  padding-bottom: 0px;
  margin-top: 0px;
  width: 100%;
  float: left;
  background: #fff;
  border-radius: 0px;
  height: auto;
  padding-top: 0px;
  display: flex;
  padding-left: 10px;
}
.invoice-details-block-table table tbody tr:nth-child(even) {
  background-color: #DCE9F2;
}
.invoice-details-block-table  .table>tbody>tr>td{
  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  height: 50px;
  vertical-align: middle;
  line-height: 38px;
  overflow: hidden;
  color: #000;
  border-right: 1px solid #16364C;
  font-size: 15px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
}
.invoice-details-block-table  .table>tbody>tr>td:last-child{
border: 0;
}
.width-33{
  width: 33.33%;
}

.epayment-outer .right-block{
  width: 40%;
  float: right;
  display: block;
  background: #16364C;
  border-radius: 10px;
  padding: 30px;
}

.payement-top-head{
  width: 100%;
  float: left;
  display: block;
  color: #fff;
  font-weight: 800;
  font-size: 23px;
}
.payement-top-head p{
  width: 100%;
  float: left;
  display: block;
  font-size: 13px;
  color: #94D4FF;
  font-weight: 300;    margin-top: 7px;
}

.payment-details-main{
  width: 100%;
  float: left;
  display: block;
  margin-top: 35px;
  margin-bottom: 15px;
}
.payment-button-block{
 width: 140px;
 margin: 0px auto;
}
.payment-button-block input[type="submit"], .payment-button-block input[type="button"] {
  color: #fff;
  text-align: center;
  border: none;
  float: left;
  display: block;
  border-radius: 10px;
  cursor: pointer;
  width: auto;
  height: 40px;
  margin-top: 35px;
  outline: 0;
  text-align: center;
  text-transform: uppercase;
  padding: 8px 4px;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  margin-bottom: 0;
  width: 100%;
  font-size: 14px;
  background: #1A82C9;
  border: 1px solid #1A82C9;
}
.payment-details-main .top-block{
  width: 100%;
  float: left;
  padding-bottom: 15px;
  display: flex;
}
.payment-details-main .top-block p{
  width: 50%;
  float: left;
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  margin: 0;
  padding: 0;
  color: #fff;
}
.payment-details-main .top-block span{
  width: 50%;
  float: left;    line-height: 15px;
  display: block;    color: #fff;
  font-size: 15px;
  font-weight: bold;    margin: 0;
  padding: 0;
}
.payment-details-main  .grand-total{
  border-top: 1px solid #4D9DD3;
  padding-top:15px;
  padding-bottom: 0;
}

.your-cart-radio-button-block{
  width: 100%;
  float: left;
  padding-top: 30px;
  display: block;
}
.card-details-block{
  width: 100%;
  float: left;

  display: block;
}

.card-details-block .form-group {
  width: 100%;
  float: left;
  position: relative;
  padding-top: 30px;
  display: block;
}
.card-details-block .form-group input[type="text"], .card-details-block .form-group input {
  display: block;
  width: 100%;
  background: none;
  font-weight: 300;
  float: left;
  border-radius: 0;
  color: #94D4FF;
  font-size: 14px;
  position: relative;
  padding: 15px 5px 12px 25px;
  outline: 0;
  margin-bottom: 0;
  background: none;
  border:0;
  outline: 0;
  border-bottom: 1px solid #fff;
}
.card-details-block .form-group label{
  width: 100%;
  float: left;
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  
}
.card-icon {
  width: 20px;
  position: absolute;
  left: 0;
  z-index: 1;
  bottom: 14px;
}
.card-detail-two-block{
  float: left;
  width: 100%;
  display: block;
  margin-top: 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 0px 30px;
  grid-auto-rows: minmax(0px, auto);
}

.card-detail-two-block .form-group input[type="text"], .card-detail-two-block .form-group input{
  padding: 15px 5px 12px 0px;   
}
.payment-button{
  width: 146px;
  float:right;
  display: block;
}

::placeholder {
  color: #94D4FF;
  opacity: 1; /* Firefox */
  font-weight: 200;
  font-size: 14px;
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #94D4FF;    font-weight: 200;
  font-size: 14px;
}
.payment-button input[type="submit"], .payment-button input[type="button"] {
  color: #003B5C;
  text-align: center;
  border: none;
  float: left;
  display: block;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  height: 40px;
  margin-top: 35px;
  text-transform: uppercase;
  outline: 0;
  text-align: center;
  padding: 8px 5px;
  font-weight: 600;
width: 100%;
text-align: center;
margin-bottom: 0;
  font-size: 14px;
  background: #FFB51C;border:1px solid #FFB51C;
}
.payment-button input[type="submit"]:hover, .payment-button input[type="button"]:hover {
  background:#003B5C;
  color: #fff;border:1px solid #003B5C;
}
.payment-secured{
  width: 100%;
  float: left;
  display: block;
  padding-top: 30px;
  color: #797979;
}
.payment-secured img{
  width: 11px;
  float: left;
  display: block;
  margin-top: 3px;
  margin-right: 7px;
}
.payment-secured p {
  width: auto;
  float: left;
  font-size: 12px;
  color: #797979;
}
.cart-terms-block{
  width: 100%;
  float: left;    margin-top: 30px;
  display: block;
}
.cart-terms-block p{
  font-size: 14px;    width: 100%;
  float: left;    margin-top: -5px;
  padding-left: 35px;
}
.cart-terms-block p span{
  font-weight: 600;
}

.radio-button-block{
  width: 100%;
  float: left;
  display: block;
}

.coverage-information p{
  width: 100%;
  float: left;
  font-size: 12px;
  padding-bottom: 14px;
}

.radio-button-block .form-group {
float: left;
width: auto;
display: block;
}

.radio-button-block  input[type="radio"] {
  display: none;
}

.radio-button-block  label {
  cursor: pointer;
position: relative;
font-size: 15px;
padding-left: 30px;
float: left;
font-weight: 400;
color: #fff;
}

.radio-button-block   label::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 3px solid #fff;
  border-radius: 50%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: border-color 400ms ease;
}

.radio-button-block label::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #FFBF51;
  border: 1px solid #FFBF51;
  border-radius: 50%;
  top: 50%;
  left: 0;
  transform: translateY(-50%) scale(0);
  transition: transform 400ms ease;
}

.radio-button-block input[type="radio"]:checked + label::before {
  border-color:#FFBF51;;
}

.radio-button-block   input[type="radio"]:checked + label::after {
  transform: translateY(-50%) scale(0.55);
}

.radio-2-grid {
  float: left;
  width: 100%;
  display: block;
  margin-top: 0px;
  display: grid;
  grid-template-columns: repeat(2,1fr) !important;
  gap: 0px 30px;
  grid-auto-rows: minmax(0px,auto);
}



.backbutton{
  width: 104px ;
  float: left;
  display: block;
}
.backbutton input[type="submit"], .backbutton input[type="button"] {
  color: #000000;
  text-align: center;
  border: none;
  float: left;
  display: block;
  border-radius: 5px;
  cursor: pointer;
  width: auto;
  height: 40px;
  margin-top: 35px;
  outline: 0;
  text-align: center;
  text-transform: uppercase;
  padding: 8px 4px;
  font-weight: 600;
text-align: center;
margin-bottom: 0;
width: 100%;
  font-size: 14px;
  background: #F7F7F7;
border:1px solid #AFAFAF;
}
.backbutton input[type="submit"]:hover, .backbutton input[type="button"]:hover {
  background:#003B5C;
  color: #fff;border:1px solid #003B5C;
}

.footer-block.davis-footer .top {
  border-bottom: 3px solid #FFB51C;
}
.accordion .accordion-item .accordion-item-description-wrapper{
display: none;
}
.accordion .accordion-item.open .accordion-item-description-wrapper{
  display: block;
  }
.expiry-date{
  width: 35%;
  float: left;
  display: block;
}
.expiry-date-right{
  width: 35%;
  float: left;
  display: block;
}
.expiry-date-middle{
  width: 20%;
  font-size: 21px;
  font-weight: 300;
  color: #fff;
  float: left;
  display: block;
  margin-top: 20px;
  text-align: center;
}
.invoice-details-block .left-subhead{
  padding-bottom: 22px;
}
.epayment-top-heading-section{
width: 100%;
float: left;
display: block;
padding-bottom: 30px;
}
.epayment-top-heading-section .epayment-arrow{
  width: 26px;
  float: left;    margin-top: 6px;
  display: block;
}
.epayment-top-heading-section .head{
  width: auto;
  float: left;
  font-size: 23px;
  float: left;
  float: left;
  font-weight: bold;
  padding-left: 15px;
  color: #16364C;
}
.ach-detail-block .form-group input[type="text"], .ach-detail-block .form-group input{
  padding-left: 0;
}.payment-secured {
  width: 100%;
  float: left;
  display: block;
  padding-top:20px;
  padding-bottom: 0px;

}
.payment-secured img {
  width: 15px;
  float: left;
  display: block;
  margin-right: 12px;
}
.payment-secured span{
  font-size: 13px;
  color: #fff;    margin-top: 7px;
  float: left;
}
.cart-terms-block {
  width: 100%;
  float: left;
  margin-top: 20px;
  display: block;
}
.coverage-checkbox {
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 27px;
  margin-right: 30px;
  float: left;
}
.cart-terms-block p {
  font-size: 14px;
  width: 100%;
  float: left;
  margin-top: -5px;
  color: #94D4FF;
  list-style: 18px;
  padding-left: 35px;
}
.cart-terms-block p span {
  font-weight: 400;color: #94D4FF;
}
.coverage-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.coverage-checkbox input:checked ~ .checkmark {
  background-color: #fff;
  border: 0.5px solid #FFBF3F;
}
.coverage-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  background-color: #fff;
  border: 1px solid #797979;
}
.coverage-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.coverage-checkbox .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #FFBF3F;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.coverage-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.empayment-container{
  width: 95%;
  margin: 0px auto;
}
.page-inner {
  width: 100%;
  float: left;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 32px;
  margin-bottom: 40px;
}.page-main-top {
  width: 100%;
  float: left;
  padding-bottom: 20px;
}
.sub-head-text {
  width: auto;
  display: block;
  font-weight: 500;
  font-size: 20px;
  float: left;
}
.payment-seach-grid {
  float: left;
  width: 100%;
  display: block;
  display: grid;
  margin-bottom: 30px;
  grid-template-columns: repeat(7, 1fr) !important;
  gap: 20px 30px;
  grid-auto-rows: minmax(0px, auto);
}
.payment-seach-grid .form-group {
  margin-bottom: 0px;
  width: 100%;
  float: left;
  display: block;
}
.payment-seach-grid .form-group label {
  color: #53565A;
  font-size: 14px;
  width: 100%;
  float: left;
  line-height: 18px;
  padding-bottom: 4px;
}

.payment-seach-grid .form-control {
  height: 35px;
  border: 0.5px solid #101820;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;   float: left;
  color: #101820;    width: 100%;
  padding-top: 8px;
}

.button-block-main{
width: 100%;
float: left;
display: block;
}
.common-button {
  float: left;
  text-align: center;
  padding: 10px 20px;    font-weight: 500;
  font-size: 14px;
  min-width: 118px;   margin-right:15px;
}.grey-button {
  background: #F4F4F4;
  border: 0.5px solid #10182040;
  border-radius: 5px;
  color: #101820;
}
.blue-button {
  background: #003B5C;
  border: 0.5px solid #003B5C;
  border-radius: 5px;
  color: #fff;
}

.grey-button:hover, .grey-button:focus {
  background: #003B5C;
  color: #fff;
}
.blue-button:hover, .blue-button:focus  {
  background: #1A82C9;    color: #fff;
}
.common-button:first-child{
  margin-left: 0;
}
.account-tabel-block{
  width: 100%;
  float: left;
  display: block;
}
.account-top-right{
  float: right;
  display: block;
  width: auto;
}
.total-button{
  float: left;
  text-align: center;
  padding: 10px 20px;
  margin-right: 15px;
  text-align: center;    font-weight: 500;
  font-size: 14px;
  border: 0.25px solid #10182040;
  background: #F4F4F4;
  color: #000C;    border-radius: 5px;
}
.total-button:last-child{
  margin-right: 0;
}
.pay-section{
  float: right;
  width: auto;
  display: block;
}
.pay-section .common-button{
  min-width: 80px;
  margin-right: 0;
}
.account-tabel-block thead th .right-icon {
  width: 8px;
  float: right;
}
.account-tabel-block .icon-table-filter {
  color: #888b8d !important;
}

.account-tabel-block th.sortable {
  position: relative;
  cursor: pointer;
}

.account-tabel-block th.sortable::after {
  font-family: FontAwesome;
  content: "\f0dc";
  position: relative;
  right: 0;
  color: #212121;
  /* float: left; */
  padding-left: 3px;
}

.account-tabel-block th.sortable.asc::after {
  content: "\f0d8";
}

.account-tabel-block th.sortable.desc::after {
  content: "\f0d7";
}

.account-tabel-block  th.sortable:hover::after {
  color: #fff;
}
.account-tabel-block .col-width-15 {
    width: 15%;
    float: left;
}

.col-width-4 {
  width: 4%;
  float: left;
}
.account-tabel-block table {
  border: 0;
  background: none;
  border-radius: 0;
  outline: none;
  float: left;
  width: 100%;
}
.account-tabel-block  table thead,  .account-tabel-block  table tbody {
  width: 100%;
  float: left;
  display: block;
}
.account-tabel-block table thead tr {
  width: 100%;
  display: flex;
  float: left;
  padding-left: 10px;
  background: #D1D3D4;
}
.account-tabel-block table>thead>tr>th {
  padding-left: 7px;
  vertical-align: middle;
  line-height: 32px;
  border: 0;   color: #000;    padding-top: 7px;
  padding-bottom: 7px;
  text-align: left;    font-size: 13px;
}  
.account-tabel-block table tbody tr{
  padding-bottom: 5px;
  margin-top: 5px;
  width: 100%;
  float: left;
  background: #F4F4F4;
  height: auto;
  padding-top: 5px;
  display: flex;
  padding-left: 10px;
}
.account-tabel-block table>tbody>tr>td {
  padding-left: 7px;
  padding-top: 0;
  padding-bottom: 0;    font-size: 13px;
  border: 0;
  vertical-align: middle;
  line-height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;    text-align: left;
}
.pagenation-outer {
  width: auto;
  float: right;
  display: block;
}
#pagination {
  margin: 0;
  padding: 0;
  text-align: center
}
#pagination li {
  display: inline
}
#pagination li a {
  display: inline-block;
  text-decoration: none;
  padding: 5px 10px;
  color: #000
}

/* Active and Hoverable Pagination */
#pagination li a {
  border-radius: 5px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s
    
}
#pagination li a.active {
  background-color: #004E7C;
  color: #fff
}
#pagination li a:hover:not(.active) {
  background-color: #ddd;
} 

/* border-pagination */
.b-pagination-outer {
  width: 100%;
float: right;
  text-align: center;

}
#border-pagination {
float: right;
width: auto;
  padding: 0;
  text-align: center;
  margin-top: 20px;
}
#border-pagination li {
  float: left;
  display: inline;
  margin: 0px 2px;

}
#border-pagination li a {
  display: block;
  text-decoration: none;
  color: #000;
  padding: 5px 10px;
  border: 1px solid #ddd;
  float: left;

}
#border-pagination li a {
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s
}
#border-pagination li a.active {
  background-color: #004E7C;
  color: #fff;
}
#border-pagination li a:hover:not(.active) {
  background: #ddd
}
#border-pagination li a.right-arrow,   #border-pagination li a.left-arrow{
  font-size: 12px;
  font-weight: 200;
}
#border-pagination li a.right-2-arrow,   #border-pagination li a.left-2-arrow
{
  font-size: 21px;
  padding: 3px 10px;
  line-height: 20px;
}
.table-one-icon {
  width: 25px;
  margin: 0px auto;
}
.document-table-view {
    margin-top: 30px;
}
.checkmark-form-control {
  border: 0;
  padding: 0;
  margin-top: 0px;
  height: auto;
  box-shadow: none;
}
.check-box-custom {
  border: 0;float: left;
  padding: 0;
  box-shadow: none;    padding-top: 5px;
  position: relative;
}
.check-box-custom input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.check-box-custom input[type=checkbox], .check-box-custom input[type=radio] {
  width: 20px;
  height: 20px;
}
.check-box-custom .checkmark {
  position: absolute;
  top: 7px;   
  left: 0;
  height: 17px;
  width: 17px;
  border-radius: 3px;
  border: 0.5px solid #DDDDDD;
}
.check-box-custom input:checked ~ .checkmark:after {
  display: block;
}
.check-box-custom .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkmark:after {
  top: 4px;
  top: 1.5px;
  width: 6px;
  height: 10px;
  border: solid #000;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 6px;
}
.account-tabel-block table>thead>tr>th .checkmark:after{
  border: solid #DDDDDD;    border-width: 0 1.5px 1.5px 0;
}.account-tabel-block table>thead>tr>th .check-box-custom .checkmark{
  border: 0.5px solid #DDDDDD;   
  background: #fff;
}
.col-width-5{
  width: 3%;
}
.modal-open .modal.modal-center {
  display: flex !important;
  align-items: center !important;
}
.modal-sucess .modal-header {
  padding: 37px 30px 9px;
  border-bottom: 0px solid #e5e5e5;
  border-radius: 0;
  background: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.modal-header .sub-head-text {
  width:100%;
  float: left;
  text-align: left;
  color: #ffff;
  display: block;
  padding-top: 4px;    font-weight:500;
  font-size: 26px;
}
.sucess-icon{
  width: 83px;
  margin: 0px auto;
  position: absolute;
  right: 103px;
  top: 71px;
  z-index: 9;
}
.sucess-content-top{
  float: left;
  width: 100%;
  display: block;
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 30px 30px;
  grid-auto-rows: minmax(0px, auto);
}
.sucess-content-block{
  width: 100%;
  float: left;
  display: block;
}
.sucess-content-block span{
  width: 100%;
  float: left;
  display: block;
  color: #94D4FF;
  font-size: 14px;
  padding-bottom: 10px;
}
.sucess-content-block  p{
  width: 100%;
  font-size: 17px;
  color: #fff;
  float: left;
  display: block;    line-height: 17px;
}
.modal-sucess .modal-body {
  position: relative;
  padding: 0px;
  float: left;
  width: 100%;
  background: #646A6E;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.sucess-content-middle{
  float: left;
  width: 100%;
  display: block;
  margin-top: 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 0px 30px;
  grid-auto-rows: minmax(0px, auto);
}
.sucess-text-block{
  width: 100%;
  float: left;
  display: block;
}.modal-sucess .modal-dialog {
  width: 678px;
}
.modal-sucess  .modal-content
{
  background: #646A6E;
  border-radius: 30px;
  width: 100%;
  float: left;
  position: relative;
  display: block;
  padding: 30px 80px;
}

.modal-sucess .modal-header .close {
  margin-top: -2px;
  position: absolute;
  right: 23px;
  top: 13px;
  text-shadow: none;
  opacity: .5;
}
.modal-sucess .modal-header .close span{
  font-size: 37px;
  color: #ffff;
  font-weight: 200;
}
.modal-sucess-inner{
  width: 100%;
  float: left;
  padding: 20px ;
  display: block;
  border-radius: 10px;
  background: #16364C;
}
.payment-details-modal-block{
width: 100%;
float: left;
display: block;
margin-top: 30px;
}
.payment-details-modal-block .heading{
  width: 100%;
  float: left;
  display: block;
  font-size: 21px;
  color: #94D4FF;   
}
.payment-details-modal-block .payment-details-main {
  margin-top: 20px;
}
.modal-ok-button{
  width: 70px;
  margin: 0px auto;
}
.modal-ok-button .common-button
{
min-width: 70px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 26px;
  color: #000;
  border: #fff;
}
.modal-ok-button .common-button:hover{
  background: #1A82C9;
  color: #fff;
}
.payment-button-block input[type="submit"]:hover, .payment-button-block input[type="button"]:hover {
  background: #337ab7;
  color: #fff;
}
.dataTables_length {
  float: left;margin-top: 13px;
}
.dataTables_length label{
  font-weight: 600;
}
.dataTables_length select{
  border: 1px solid #555;
  border-radius: 4px;
  margin-left: 3px;
  margin-right: 3px;
  height: 24px;
}
.epayment-first .head{
  padding-left: 0;
}
.card-details-block .form-group.acc-name input[type="text"], .card-details-block .form-group.acc-name input{
padding-left: 0;
}

@media only screen and (max-width: 1500px) {
  .davis-payment-block .left-area {
      width: 67%;

  }
  .davis-payment-block .right-area {
      width:30%;
  }
  .account-tabel-block table>thead>tr>th, .account-tabel-block table>tbody>tr>td{
      font-size: 13px;    padding-left: 2px;
  }
}
@media only screen and (max-width: 1300px) {


  .account-tabel-block{
      width: 100%;
      float: left;
      display: block;
      overflow: scroll;
      display: block;
      display: flex;
      flex-wrap: nowrap;
      overflow: auto;
      margin-bottom: 15px;
  }
   .account-tabel-block table {
      border-collapse: collapse;
      /* overflow-x: auto; */
      display: block;
      width: fit-content;
      width: auto  !important;
      float: left;
      max-width: 5000;
  }   
   .account-tabel-block table thead {
      width: auto !important;
  }
   .account-tabel-block table tr {
      width: auto;
      display: flex;
  }
   .account-tabel-block  table tbody {
     width: auto !important;
      display: flex;
  }
  .account-tabel-block  .col-width-15 {
   width: 150px;
   }
   .account-tabel-block  .col-width-5 {
      width: 50px;
      }
      .payment-seach-grid {
          grid-template-columns: repeat(4, 1fr) !important;
      }
      .main-container {
          width: 90%;
      }
}
@media only screen and (max-width: 1200px) {
  .davis-payment-block .left-area {
      width: 62%;

  }
  .davis-payment-block .right-area {
      width:35%;
  }
  .epayment-outer .right-block {
      width: 45%;
      padding: 30px 30px;
  }
  .epayment-outer {
      padding: 30px;
  }
}

@media only screen and (max-width: 980px) {
  .davis-payment-block .left-area {
      width: 100%;

  }
  .davis-payment-block .right-area {
      width:100%;
      margin-top: 30px;
  }  .payment-seach-grid {
      grid-template-columns: repeat(2, 1fr) !important;
  }
  .epayment-outer .left-block {
      width: 100%;
  }
  .epayment-outer .right-block {
      width: 100%;
      margin-top: 30px;
  }
  .payment-button-block input[type="submit"], .payment-button-block input[type="button"] {
      margin-top: 20px;
  }
  .payment-list-block span {
      padding-bottom: 4px;
  }
  .payment-list-block {
      padding-bottom: 20px;
  }
  .modal-sucess .modal-dialog {
      width: 90%;
  }
  .modal-sucess .modal-content {
      padding: 14px 38px 30px;
  }
  .modal-header .sub-head-text {
      padding-top: 0;
  }
  .sucess-icon {
      width: 68px;
      right: 81px;
      top: 59px;
  }
  .modal-header .sub-head-text {
      font-size: 24px;
  }
  .modal-sucess-inner {
      padding: 10px 20px;
  }
  .sucess-content-top {
      margin-top: 20px;
      gap: 20px 20px;
  }
}



@media only screen and (max-width: 767px) {
  .accordion-item-description .content-block {
      width: 100%;
      margin-bottom: 20px;
  }
  .accordion-item-description .content-block:last-child{
      margin-bottom: 0;
  }
  .radio-button-block label {
      font-size: 15px;
  } [data-pagination] a {
      min-width: 28px;
              line-height: 15px;
  }
      [data-pagination] ul li {
      padding-left: 1px;
      padding-right: 1px;
  }
      [data-pagination] ul {
       padding: 0.5em 1px;
  }
      .page-top-right {
      width: 100%;
  }
      [data-pagination] a.right-arrow {
      float: right;
      padding-top: 10px;
      line-height: 13px;
          height: 33px;
  }
  [data-pagination] .disabled, [data-pagination] [hidden], [data-pagination] [disabled] {
        padding-top: 10px;
      line-height: 14px;
      height: 33px;
  }
  .payment-seach-grid {
      grid-template-columns: repeat(1, 1fr) !important;
  }
  .account-top-right {
      float: left;
      display: block;
      width: 100%;

  }.total-button{
      margin-top: 20px;
  }
  .card-detail-two-block {
      grid-template-columns: repeat(1, 1fr) !important;
  }
  .modal-sucess .modal-header {
      padding: 28px 6px 9px;
  }
  .modal-sucess .modal-content {
      padding: 14px 20px 30px;
  }    .payment-details-main {
      margin-bottom: 0;
      padding: 20px 0px;
  }
  .card-details-block .form-group {
      padding-top: 20px;
  }
  .payment-details-main {
      padding: 20px 0px;
      margin-top: 10px;
  }
}


@media only screen and (max-width: 480px) {
  .payment-details-main {
      padding: 20px 0px;
  }
  .payment-button-block {
      width: 150px;
  }
  .payment-button {
      width: 126px;
  }
  #border-pagination li a {
      padding: 5px 7px;
  }.sucess-content-top {
      grid-template-columns: repeat(1, 1fr) !important;
  }
  .payment-details-modal-block .payment-details-main {
      margin-top: 6px;
  }
  .modal-header .sub-head-text {
      font-size: 20px;
  }
  .sucess-icon {
      width: 45px;
      right: 49px;
      top: 50px;
  }
}