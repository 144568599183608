
/*Fonts*/
// $fontEuropa: 'Europa';
$fontEuropaRegular: 'Europa Regular';
// $fontSegoeUi: 'Segoe UI';

// $primary-color: #004c97;
// $secondary-color: #004c97;
// $tertiary-color: #07737a;

/*Card*/
// $card-background-color: white;
// $card-header-background-color: #f3f3f3;
// $card-subheader-background-color: #e5eff0;
// $subcard-separator-color: #ccc;
// $card-header-min-height: 55px;

/*Weights*/

$light-font: 300;
$semi-light-font: 400;
$semi-bold-font: 600;
$bold-font: 700;

$btn-primary-bg: #15364C;
$btn-primary-bg-hover: #15364c;

$btn-secondary-bg: rgba(209, 209, 209, 1);
$btn-secondary-bg-hover: #b3b3b3;
